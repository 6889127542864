var blog;

blog = function () {

  var coverHover = function () {
    var $coverEntry = $('.blog-entries__item');
    var $coverEntryHover = $('.blog-entries__item--hover');

    $coverEntry.on('mouseenter mouseleave', function () {
      if ( ! $(this).parents('.blog-entries-featured__left').length ) {
        $(this).find($coverEntryHover).stop(true, true).fadeToggle(250);
      }
    });
  
  }

  var socialMediaPosition = function () {

    var $goBack = $('.blog-back').outerHeight();
    var $blogBreadCrumb = $('.blog-breadcrumb').outerHeight(true);

    var $blogIntroOffset = $('.blog-entry__intro').offset().top;

    var $socialMediaPosition = parseInt($blogIntroOffset - ($goBack + $blogBreadCrumb));


    var $socialMedia = $('.blog-entry-social');

    $socialMedia.css({
      'margin-top': $socialMediaPosition
    })

  }

  var resetImageWidthHeight = function () {
    $('.blog-entry__main').find('img').attr('style', '');
  }

  
  coverHover();

  /**
   * Only display on the blog entry page (show)
   */
  if ( $('.blog-entry').length ) {
    socialMediaPosition();
  }
  
  resetImageWidthHeight();



};

$(document).on('turbolinks:load', blog);