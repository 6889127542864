import ahoy from "ahoy.js";

const ready = function () {
  console.log("events page");
  $(".trigger-filter").click(function () {
    // console.log('trigger filter');
    if ($(".filter-menu").hasClass("nav-closed")) {
      $(".filter-menu").removeClass("nav-closed");
      $(".filter-menu").addClass("nav-open");
      return false;
    } else {
      $(".filter-menu").removeClass("nav-open");
      $(".filter-menu").addClass("nav-closed");
      return false;
    }
  });
  $("#search").autocomplete({
    source: "/cpd_events/suggestions",
    minLength: 2,
    focus: function (event, ui) {
      $("#search").val(ui.item.title);
      return false;
    },
    select: function (event, ui) {
      $("#search").val(ui.item.title);
      return false;
    },
  });
  var autocomplete = $("#search").data("ui-autocomplete");
  if (autocomplete)
    autocomplete._renderItem = function (ul, item) {
      return $("<li>").append(item.title).appendTo(ul);
    };
  // $('.ui-widget-content').insertAfter($('.autofill'));
  $(".autofill").append($(".ui-widget-content"));

  /**
   * Tracking with Ahoy
   * tracking external links
   **/

  $(".resource__accordian .panel-body a").on("click", function (e) {
    ahoy.track("CPD Resource Link", e.target.closest(".panel-body").dataset);
  });
};
// $(document).ready(ready);
$(document).on("turbolinks:load", ready);
