import Player from "@vimeo/player";
import ahoy from "ahoy.js";

const ready = function () {
  $("input.t").change(function () {
    console.log($("#search-form").attr("action"));
    $.ajax({
      url: $("#search-form").attr("action"),
      data: $("#search-form").serialize(),
    });
  });

  $("input.e").change(function () {
    console.log($("#event-form").attr("action"));
    $.ajax({
      url: $("#event-form").attr("action"),
      data: $("#event-form").serialize(),
    });
  });

  // convert http to https for vimeo
  // $(".video--thumbnail").each(function () {
  //   $(this).attr("src", $(this).attr("src").replace("http://", "https://"));
  // });

  /**
   * Tracking with Ahoy
   * tracking download for cpd resource along with ahoy gem
   **/

  $("iframe").each(function () {
    const iframe = this;
    const player = new Player(iframe);
    let number_of_plays = 0;

    player.on("play", function (data) {
      number_of_plays++;
      if (number_of_plays === 1) {
        /*
         * Get dataset of vimeo iframe which includes the title and if of the video
         * This will be pushed to the Ahoy Event
         */
        ahoy.track("Video has been played", {
          id: iframe.data("id"),
          title: iframe.data("title"),
        });
      }
      console.log(e);
    });
  });
};

// $(document).ready(ready);
$(document).on("turbolinks:load", ready);
