const ready = () => {
  $("#notice").delay(1500).fadeOut();
  $('[data-toggle="popover"]').popover();
  $(".close-navigation").click(function () {
    // console.log('clicked to collapse');
    $(".navigation-menu").removeClass("nav-open");
    $(".navigation-menu").addClass("nav-closed");
  });
  $(document).on("touchstart", function () {
    $(".navigation-menu").removeClass("nav-open");
    $(".navigation-menu").addClass("nav-closed");
  });
};

$(document).on("turbolinks:load", ready);
