const accept_terms = () => {
  $.ajax({
    type: "POST",
    url: "/accept-terms",
    dataType: "json",
    success: () => $(".terms-updated").hide(),
  });
};

const ready = () => {
  $(".terms-updated .btn").on("click", accept_terms);
};

$(document).on("turbolinks:load", ready);
