import gtag from "./gtag";

const openUrl = (url, width = 640, height = 480) =>
  window.open(
    url,
    "popup",
    [
      `width=${width}`,
      `height=${height}`,
      `left=${screen.width / 2 - width / 2}`,
      `top=${screen.height * 0.3 - height / 2}`,
      "menubar=no",
      "status=no",
      "location=no",
    ].join(",")
  );

const shareUrl = (site = "", url = "", { title = "" } = {}) => {
  switch (site) {
    case "twitter":
      openUrl(
        `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
        650,
        300
      );
      return true;
    case "facebook":
      openUrl(`http://www.facebook.com/sharer/sharer.php?u=${url}`, 555, 400);
      return true;
  }
  return false;
};

const ready = () => {
  $("div.social-share-button a.ssb-icon").on("click", (e) => {
    const a = e.target.parentNode;
    const div = a.parentNode;

    const site = a.getAttribute("data-site");
    const url = encodeURIComponent(
      div.getAttribute("data-url") || location.href
    );
    const title = encodeURIComponent(div.getAttribute("data-title") || "");
    const desc = encodeURIComponent(div.getAttribute("data-desc") || "");

    gtag({ event: "share", site, url, title });
    shareUrl(site, url, { title, desc });

    return false;
  });
};

$(document).on("turbolinks:load", ready);
