var blogComment;

blogComment = function () {
  var $reply = $('.blog-action-button.reply');
  
  $reply.on('click', function() {
    $(this).next('.blog-comments__add--reply').toggle(250);
  });
};

$(document).on('turbolinks:load', blogComment);