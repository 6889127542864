import $ from "jquery";
import "jquery-ui";
import "jquery-ui/ui/widgets/autocomplete";

import "jquery-datetimepicker";
import "select2";
import "bootstrap-sass";

import "gsap";
import "gsap/EasePack";
import "gsap/ScrollToPlugin";

// TODO: gmaps/google
// TODO: cluster (MarkerClusterer)

import "./turbolinks_transitions";
import "./parsley-init";
import "./ratyrate";

import "./blog";
import "./blog_comments";
import "./cpd_events";
import "./cpd_streams";
import "./forms";
import "./home";
import "./main";
import "./resources";
import "./school_event";
import "./universitycpdform";
import "./video";

import "./social-share";
import "./terms_updated";

window.$ = $;

$(document).on("click", ".video-card", function (e) {
  $(".video-card").removeClass("active");
  $(this).addClass("active");
});
