import ahoy from "ahoy.js";

const ready = function () {
  $("input.e").change(function () {
    console.log($("#event-form").attr("action"));
    $.ajax({
      url: $("#event-form").attr("action"),
      data: $("#event-form").serialize(),
    });
  });

  $(".stream-selection").on("click", function (ev) {
    ev.preventDefault();
    $(".dropdown").toggleClass("dropdown-open");
  });
  $(".favoutite-trigger").on("click", function (ev) {
    ev.preventDefault();
    $(".favourites-nav").toggleClass("favourite-open");
  });

  /**
   * Tracking with Ahoy
   * tracking download for cpd resource along with ahoy gem
   **/

  $("#cpd-resource-view-in-browser").on("click", function (e) {
    ahoy.track("CPD View in Browser", e.target.parentNode.dataset);
  });

  $("#cpd-resource-pdf-view").on("click", function (e) {
    ahoy.track("CPD PDF Viewer", e.target.parentNode.dataset);
  });

  $("#cpd-resource-download").on("click", function (e) {
    ahoy.track("CPD Download Resource", e.target.parentNode.dataset);
  });
};
// $(document).ready(ready);
$(document).on("turbolinks:load", ready);
