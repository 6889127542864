var ready;
ready = function() {
  console.log('cpd event');
  $('#cpd_event_no_date').on('change', function() {
    if ($(this).is(':checked')) {
      $('#date_start').hide();
      $('#date_end').hide();
      // console.log('show');
    } else {
      $('#date_start').show();
      $('#date_end').show();
      // console.log('hide');
    }
  });
  $('.date-start').datetimepicker({
    format: 'd/m/Y H:i'
  });
  $('.date-end').datetimepicker({format:'d/m/Y H:i'});
  // $('.date-start').datetimepicker({});
  // $('.date-end').datetimepicker({});

  $('#cpd_event_subject_other').hide();
  $('#cpd_event_benefit_other').hide();
  $('#cpd_event_programme_other').hide();
  $('#cpd_event_region_other').hide();
  $('#cpd_event_event_type_other').hide();


  $('#cpd_event_event_type_ids_5').on('change', function() {
    if ($(this).is(':checked')) {
      $('#cpd_event_event_type_other').show();
    } else {
      $('#cpd_event_event_type_other').hide();
    }
  });

  $('#cpd_event_subject_ids_7').on('change', function() {
    if ($(this).is(':checked')) {
      $('#cpd_event_subject_other').show();
    } else {
      $('#cpd_event_subject_other').hide();
    }
  });

  $('#cpd_event_region').on('change', function() {
    // console.log($(this).val());
    if ($(this).val() == "Other") {
      $('#cpd_event_region_other').show();
    } else {
      $('#cpd_event_region_other').hide();
    }
  });

  $('#cpd_event_subject_ids_6').on('change', function() {
    if ($(this).is(':checked')) {
      $('#cpd_event_programme_other').show();
    } else {
      $('#cpd_event_programme_other').hide();
    }
  });


  $('#cpd_event_benefit_ids_6').on('change', function() {
    if ($(this).is(':checked')) {
      $('#cpd_event_benefit_other').show();
    } else {
      $('#cpd_event_benefit_other').hide();
    }
  });

  $('#cpd_event_url').on('blur', function(ev) {
    var initialVal = $(this).val();
    var lcVal = initialVal.toLowerCase();
    if (lcVal.substr(0,7) === 'http://' || lcVal.substr(0,8) === 'https://') {
      $(this).val(lcVal);
    } else {
      $(this).val('http://' + lcVal );
    }
  });

};

// $(document).ready(ready);
$(document).on('turbolinks:load', ready);
