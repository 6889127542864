var ready;
ready = function() {
  // Scholl type
  if ($('#cpd_school_event_school_type').find(":selected").text() != "OTHER") {
     $('#cpd_school_event_type_other').parent().hide();
  }

  $('#cpd_school_event_school_type').on('change', function() {
    if (this.value == "OTHER") {
      $('#cpd_school_event_type_other').parent().show();
    } else {
      $('#cpd_school_event_type_other').parent().hide();
      $('#cpd_school_event_type_other').val('');
    }
  });
  // Scholl type
  if ($('#cpd_school_event_age_range').find(":selected").text() != "OTHER") {
     $('#cpd_school_event_age_other').parent().hide();
  }
  $('#cpd_school_event_age_range').on('change', function() {
    if (this.value == "OTHER") {
      $('#cpd_school_event_age_other').parent().show();
    } else {
      $('#cpd_school_event_age_other').parent().hide();
      $('#cpd_school_event_age_other').val('');
    }
  });
  // Focus
  // alert($('#cpd_school_event_focus').find(":selected").text());

  if ($('#cpd_school_event_focus').find(":selected").text() != "Other, provide details:") {
     $('#cpd_school_event_focus_other').parent().hide();
 }
  $('#cpd_school_event_focus').on('change', function() {
    if (this.value == "Other, provide details:") {
      $('#cpd_school_event_focus_other').parent().show();
    } else {
      $('#cpd_school_event_focus_other').parent().hide();
      $('#cpd_school_event_focus_other').val('');
    }
  });
};

// $(document).ready(ready);
$(document).on('turbolinks:load', ready);
