var readyHome;
readyHome = function() {
  // Main navigation
  if ($('.vc-message').length > 0) {
    $('.vc-message').delay(10000).animate({'opacity':0},200);
  }
  changeTiles();
  $('.trigger-navigation').click(function() {
      if ($('.navigation-menu').hasClass('nav-closed')) {
        $('.navigation-menu').removeClass('nav-closed');
        $('.navigation-menu').addClass('nav-open');
        $('.page-content').addClass('no-touch');
      } else {
        $('.navigation-menu').removeClass('nav-open');
        $('.navigation-menu').addClass('nav-closed');
        $('.page-content').removeClass('no-touch');
      }
      return false;
  });
  $('.navigation-menu').mouseleave(function() {
      // $('.navigation-menu').css({'display':'block'});
      $('.navigation-menu').addClass('nav-closed');
      $('.navigation-menu').removeClass('nav-open');
      $('.page-content').removeClass('no-touch');
  });
  $('.nav-item').mouseenter(function(ev) {
    if ($(document).width() > 780 ) {
      var myName = $(this).attr('data-id');
      var offset = $(this).offset();
      var h = $(this).height();
      var p = $(ev.currentTarget).parent().outerWidth() - $(ev.currentTarget).parent().innerWidth();
      // console.log(p);
      $('.nav-item').removeClass('active-link');
      $('.menu-two').removeClass('menu-show');
      $('.menu-two').css({'top': offset.top - 41 + 'px'});
      $(this).addClass('active-link');
      $('#nav' + myName).addClass('menu-show');
      $('#parent-nav').addClass('menu-half');
    }
  });
};
// $(document).ready(readyHome);
$(document).on('turbolinks:load', readyHome);

$(window).resize(function() {
  changeTiles();
})
function changeTiles() {
  console.log('change tiles');
  var vh = $('.video-height').height();
  var varHeight = vh/4;
  $('.variable-height').css({'height':varHeight});
}
