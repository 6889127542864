var updateOrganisationDisplay = function () {
  if ($('#user_organisation, #message_user_attributes_organisation').is(':checked')) {
    $('#user_postcode, #message_user_attributes_postcode').parent().removeClass('hide');
  }
  else {
    $('#user_postcode, #message_user_attributes_postcode').parent().addClass('hide');
  }
};

var loadForms = function() {
  updateOrganisationDisplay();

  $('#user_organisation, #message_user_attributes_organisation').change(function() {
    var v = $("#user_school, #message_user_attributes_school").val();
    $("#user_school, #message_user_attributes_school").trigger("focus").val(v);
    updateOrganisationDisplay();
  });

  $('#user_school, #message_user_attributes_school').autocomplete({
      source: "/schools/show_schools",
      minLength: 2,
      focus: function( event, ui ) {
          $('#user_school, #message_user_attributes_school').val( ui.item.schname );
          return false;
      },
      change: function (event, ui) {
        if ($('#user_organisation, #message_user_attributes_organisation').is(':checked')) {
          $(this).removeClass('parsley-error');
          $('.school-error').css({'display':'none'});
        } else if (ui.item == null) {
          $(this).val((ui.item ? ui.item.id : ""));
          var newClass = ui.item ? "parsley-success" : "parsley-error";
          $(this).addClass(newClass);
          $('.school-error').css({'display':'block'});
          clearFills();
          return false;
        }
      },
      select: function( event, ui ) {
        $('#user_school, #message_user_attributes_school').val(ui.item.schname);
        $('#user_urn, #message_user_attributes_urn').val(ui.item.urn);
        $('#user_postcode, #message_user_attributes_postcode').val(ui.item.postcode);
        return false;
      }
    });
  var autocomplete = $('#user_school, #message_user_attributes_school').data('ui-autocomplete');
  if (autocomplete) autocomplete._renderItem = function( ul, item ) {
    return $( "<li>" )
      .append( item.schname + ', ' + item.postcode )
      .appendTo( ul );
  };
  $('.autofill').append($('.ui-widget-content'));

  var set_other_display = function (role_field) {
    if ($(role_field).find("option:selected").text() == "Other") {
      $('#other').show();
    } else {
      $('#other').hide();
    }
  };

  set_other_display('#user_role_id, #message_user_attributes_role_id');

  $('#user_role_id, #message_user_attributes_role_id').on('change', function() {
    set_other_display(this);
  });

  $('form:has(#user_urn, #message_user_attributes_urn)').on('submit', function (event) {
    if ($(this).find('#user_organisation, #message_user_attributes_organisation').is(':checked')) {
      $(this).find('#user_urn, #message_user_attributes_urn').val('');
    }
  });

  $("#search-form").parsley();

  $('select').select2({ width: '80%' });
};
$(document).ready(loadForms);
$(document).on('turbolinks:load', loadForms);

function clearFills() {
  $('#user_urn, #message_user_attributes_urn').val('');
  $('#user_postcode, #message_user_attributes_postcode').val('');
}
